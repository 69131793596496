import React from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import './style.css';
//import ThemeContext from '../context/ThemeContext';

type Props = {
  className: string;
};

export const DarkModeSwitch: React.FC<Props> = ({ className }) => {
  const [isDark, setIsDark] = useDarkMode();

  return (
    <div className={className}>
      <div
        className='dark-mode-icon-wrapper'
        onClick={() => {
          setIsDark(!isDark);
        }}
      >
        <div className={`moon-or-sun${isDark ? ' dark' : ''}`} />
        <div className={`moon-mask ${isDark ? 'dark' : ''}`} />
      </div>
    </div>
  );
};
