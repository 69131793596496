import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { DarkModeSwitch } from './components/DarkModeSwitch';

function App() {
  return (
    <div className='main h-screen w-full bg-white dark:bg-gray-800 dark:text-gray-100 flex items-center content-center justify-center'>
      <DarkModeSwitch className='fixed top-6 right-6' />
      <div className='flex flex-row space-x-9'>
        <div>
          <h1 className='font-bold text-2xl lg:text-6xl'>Dominik Skalník</h1>
          <h2 className='font-normal text-xl lg:text-4xl'>Software engineer</h2>
        </div>
        <div className='font-bold text-6xl lg:text-8xl'>{'>_'}</div>
      </div>
    </div>
  );
}

export default App;
